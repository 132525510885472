import React from "react";
import { Link } from "gatsby";
import Author from "./Author";
import TagCloud from "./TagCloud";
import { SmallDivider, SmallText, PageDivider, ShowOnMobile } from "./ui-components";
import styled from "styled-components";
import TwitterSvg from "../images/twitter.svg";
import FacebookSvg from "../images/facebook.svg";
import InstagramSvg from "../images/instagram.svg";
import MailSvg from "../images/mail.svg";

const SidebarWrapper = styled.div`
  @media only screen and (min-width: 769px) {
    padding-left: 2rem;
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    background-color: #f2f2f2;
    width: calc(100% + 1.8rem);
    margin-left: -0.9rem;
    padding: 0.9rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  @media only screen and (max-width: 449px) {
    width: calc(100% + 1.3rem);
    margin-left: -0.65rem;
    padding: 0.65rem;
  }
`;

const SidebarBlock = styled.div`
  margin-bottom: 1.75rem;

  .block-header {
    margin-bottom: 0;
    color: 0, 0, 0, 0.84;
  }

  @media only screen and (max-width: 768px) {
    .block-header {
      font-weight: bold;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledSmallText = styled(SmallText)`
  margin-bottom: 0.5rem;
`;

const SocialLink = styled.a`
  position: relative;

  svg {
    transition: fill 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    position: absolute;
    top: 50%;
    left: -1.5rem;
    transform: translateY(-40%);
    fill: rgba(0, 0, 0, 0.55);
    height: auto;
    width: 20px;
  }

  &:visited {
    svg {
      fill: rgba(0, 0, 0, 0.55);
    }
  }

  &:hover,
  &:active,
  &:focus {
    svg {
      fill: rgba(0, 0, 0, 0.75);
    }
  }
`;

export default function Sidebar({ settings, tags, authors }) {
  const { email, facebook, instagram, twitter } = settings.contactInfo;
  const { description, title } = settings;

  const formattedAuthors = authors.map((author) => {
    return <Author author={author} key={author.id} />;
  });

  return (
    <SidebarWrapper>
      <SidebarBlock>
        <p className={"block-header"}>About</p>
        <SmallDivider />
        <p style={{ marginBottom: ".5rem", color: "0,0,0,0.84" }}>{title}</p>
        <StyledSmallText>{description}</StyledSmallText>
        <StyledSmallText>
          <Link to={"/about/"}>More Information</Link>
        </StyledSmallText>
      </SidebarBlock>

      <SidebarBlock>
        <p className={"block-header"}>Contact Us</p>
        <SmallDivider />
        <ul style={{ listStyle: "none" }}>
          {twitter ? (
            <li>
              <StyledSmallText>
                <SocialLink href={twitter} target="_blank" rel="noreferrer noopener">
                  <TwitterSvg />
                  Twitter
                </SocialLink>
              </StyledSmallText>
            </li>
          ) : null}
          {facebook ? (
            <li>
              <StyledSmallText>
                <SocialLink href={facebook} target="_blank" rel="noreferrer noopener">
                  <FacebookSvg />
                  Facebook
                </SocialLink>
              </StyledSmallText>
            </li>
          ) : null}
          {instagram ? (
            <li>
              <StyledSmallText>
                <SocialLink href={instagram} target="_blank" rel="noreferrer noopener">
                  <InstagramSvg />
                  Instagram
                </SocialLink>
              </StyledSmallText>
            </li>
          ) : null}
          {email ? (
            <li>
              <StyledSmallText>
                <SocialLink href={`mailto:${email}`}>
                  <MailSvg />
                  Email
                </SocialLink>
              </StyledSmallText>
            </li>
          ) : null}
        </ul>
      </SidebarBlock>

      <SidebarBlock>
        <p className={"block-header"}>Tags</p>
        <SmallDivider />
        <TagCloud tags={tags} />
      </SidebarBlock>

      <SidebarBlock>
        <p className={"block-header"}>Editors</p>
        <SmallDivider />
        {formattedAuthors}
      </SidebarBlock>
    </SidebarWrapper>
  );
}
