import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const SingleTag = styled(Link)`
  color: #757575;
  padding: 5px 10px;
  background-color: #f2f2f2;
  text-decoration: none;
  margin: 0 0.5rem 0.5rem 0;
  border-radius: 3px;
  font-size: 15px;

  &:last-child {
    margin-right: 0;
  }

  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

  &:visited {
    color: #757575;
  }

  &:hover,
  &:active,
  &:focus {
    color: rgba(0, 0, 0, 0.8);
    background-color: #e4e4e4;
  }

  @media only screen and (max-width: 768px) {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const CloudWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
`;

export default function TagCloud({ tags }) {
  const formattedTags = tags.map((tag) => {
    return (
      <SingleTag to={`/tag/${tag.slug.current}/`} key={tag.id}>
        {tag.tagName}
      </SingleTag>
    );
  });

  return <CloudWrapper>{formattedTags}</CloudWrapper>;
}
