import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Sidebar from "../components/Sidebar";
import styled from "styled-components";
import {
  PageTitle,
  PageDivider,
  MaxWidth,
  FlexRow,
  Col66,
  Col33,
} from "../components/ui-components";

const Typography = styled.div`
  color: rgba(0, 0, 0, 0.8);
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    border-bottom: 2px solid rgb(73, 126, 107);
    padding: 1px 2px 0;
    -webkit-transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
    transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
    color: inherit;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      outline-width: 0;
      color: #000;
      background-color: rgba(73, 126, 107, 0.5);
      border-bottom: 2px solid rgba(73, 126, 107, 0.5) !important;
    }
  }
`;

export const query = graphql`
  query NotFoundPageQuery {
    allSanitySiteSettings {
      nodes {
        contactInfo {
          email
          facebook
          instagram
          twitter
        }
        description
        title
      }
    }
    allSanityAuthor {
      nodes {
        image {
          asset {
            fixed(width: 40) {
              ...GatsbySanityImageFixed
            }
          }
        }
        id
        name
        slug {
          current
        }
      }
    }
    allSanityBlogPostTag {
      nodes {
        tagName
        slug {
          current
        }
        id
      }
    }
  }
`;

const NotFoundPage = (props) => {
  const { data } = props;

  return (
    <Layout>
      <SEO title="404: Not found" />
      <MaxWidth>
        <PageTitle>PAGE NOT FOUND</PageTitle>
      </MaxWidth>
      <PageDivider />
      <MaxWidth>
        <FlexRow>
          <Col66>
            <Typography>
              <p>We couldn't find this page. Maybe it's out there somewhere...</p>
              <p>
                You can always find insightful stories on our <Link to={"/"}>homepage</Link> or in
                our <Link to={"/archive/"}>archive</Link>.
              </p>
            </Typography>
          </Col66>
          <Col33>
            <Sidebar
              settings={data.allSanitySiteSettings.nodes[0]}
              tags={data.allSanityBlogPostTag.nodes}
              authors={data.allSanityAuthor.nodes}
            />
          </Col33>
        </FlexRow>
      </MaxWidth>
    </Layout>
  );
};

export default NotFoundPage;
